<template>
  <div class="parametros mt-5">
    <!-- <div class="text-end text-light">
      <Cerrar />
    </div> -->
    <div class="row">
      <h4 class="text-light">
        Parámetros del Sistema {{ objParametros.tipo }}
      </h4>
    </div>

    <div class="row mt-3">
      <div class="col-md-4">
        <label class="text-light">Monto Min Recarga</label>
        <input
          v-model="objParametros.monto_min_recarga"          
          type="text"
          class="form-control"              
        />
      </div>
      <div class="col-md-4">
        <label class="text-light">Monto Max Recarga</label>
        <input
          v-model="objParametros.monto_max_recarga"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-md-4">
        <label class="text-light">Monto Min Apuesta</label>
        <input
          v-model="objParametros.monto_min_apuesta"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4">
        <label class="text-light">Monto Max Apuesta Directa</label>
        <input
          v-model="objParametros.monto_max_apuesta_directa"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-md-4">
        <label class="text-light">Monto Min Retiro</label>
        <input
          v-model="objParametros.monto_min_retiro"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-md-4">
        <label class="text-light">Monto Max Retiro</label>
        <input
          v-model="objParametros.monto_max_retiro"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-4">
        <label class="text-light">Bonificacion Max</label>
        <input
          v-model="objParametros.bonif_max"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-md-4">
        <label class="text-light">Monto Max retiro pago movil</label>
        <input
          v-model="objParametros.monto_max_retiro_pagomovil"
          type="text"
          class="form-control"
        />
      </div>
    </div>

    <div class="row justify-content-center py-4">
      <div class="col-md-2">
        <button
          class="myButtonAceptar"
          @click="submitParametros"
          v-if="objParametros.tipo != ''"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue-demi";
import { useStore } from "vuex";
import useParametros from "../composable/useParametros";
import Swal from "sweetalert2";
import useUtil from '../../../use/useUtil';
export default {
  setup() {
    const objParametros = inject("objParametros");
    const store = useStore();
    const { editParametros, createParametros, getParametros } = useParametros();
    const {ConvertStringtoDecimal} = useUtil();
    const submitParametros = async () => {
      let resp = "";
      store.commit("showLoader");
      if (objParametros.id) {
        objParametros.monto_min_recarga = ConvertStringtoDecimal(objParametros.monto_min_recarga); 
        objParametros.monto_max_recarga = ConvertStringtoDecimal(objParametros.monto_max_recarga);        
        objParametros.monto_min_apuesta = ConvertStringtoDecimal(objParametros.monto_min_apuesta);        
        objParametros.monto_max_apuesta_directa = ConvertStringtoDecimal(objParametros.monto_max_apuesta_directa); 
        objParametros.monto_min_retiro = ConvertStringtoDecimal(objParametros.monto_min_retiro); 
        objParametros.monto_max_retiro = ConvertStringtoDecimal(objParametros.monto_max_retiro); 
        objParametros.bonif_max = ConvertStringtoDecimal(objParametros.bonif_max); 
        objParametros.monto_max_retiro_pagomovil = ConvertStringtoDecimal(objParametros.monto_max_retiro_pagomovil);        
        objParametros.limite_pago = ConvertStringtoDecimal(objParametros.limite_pago);        
        objParametros.limite_apuesta = ConvertStringtoDecimal(objParametros.limite_apuesta);          
        resp = await editParametros(objParametros);
      } else {
        objParametros.monto_min_recarga = ConvertStringtoDecimal(objParametros.monto_min_recarga); 
        objParametros.monto_max_recarga = ConvertStringtoDecimal(objParametros.monto_max_recarga);        
        objParametros.monto_min_apuesta = ConvertStringtoDecimal(objParametros.monto_min_apuesta);        
        objParametros.monto_max_apuesta_directa = ConvertStringtoDecimal(objParametros.monto_max_apuesta_directa); 
        objParametros.monto_min_retiro = ConvertStringtoDecimal(objParametros.monto_min_retiro); 
        objParametros.monto_max_retiro = ConvertStringtoDecimal(objParametros.monto_max_retiro); 
        objParametros.bonif_max = ConvertStringtoDecimal(objParametros.bonif_max); 
        objParametros.monto_max_retiro_pagomovil = ConvertStringtoDecimal(objParametros.monto_max_retiro_pagomovil);        
        objParametros.limite_pago = ConvertStringtoDecimal(objParametros.limite_pago);        
        objParametros.limite_apuesta = ConvertStringtoDecimal(objParametros.limite_apuesta);          
        resp = await createParametros(objParametros);
      }
      objParametros.id = null;
      objParametros.tipo = "";
      objParametros.monto_min_recarga = 0;
      objParametros.monto_max_recarga = 0;
      objParametros.monto_min_apuesta = 0;
      objParametros.monto_max_apuesta_directa = 0;
      objParametros.monto_min_retiro = 0;
      objParametros.monto_max_retiro = 0;
      objParametros.bonif_max = 0;
      objParametros.monto_max_retiro_pagomovil = 0;
      objParametros.limite_pago = 0;
      objParametros.limite_apuesta = 0;
      objParametros.status = true;
      objParametros.deleted = false;
      objParametros.clacificacion = "";

      await getParametros();
      store.commit("hideLoader");

      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }
    };

    return { submitParametros, objParametros };
  },
};
</script>

<style scoped>
.parametros {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  width: 50em;
  margin: auto;
}

@media (max-width: 1000px) {
  .parametros {
    width: 100%;
  }
}
</style>
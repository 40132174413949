<template>
  <form @submit.prevent="submitHipismo" class="hipismo mt-5">
    <div class="text-end text-light">
      <button class="btnEliminarTable" @click="cerrarHipismo">X</button>
    </div>
    <div class="row">
      <h4 class="text-light">
        Parámetros del Sistema {{ objParametros.tipo }}
      </h4>
    </div>

    <div class="row mt-3">
      <div class="col-md-4">
        <label class="text-light">Clacificación</label>
        <input
          v-model="objParametros.clacificacion"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-md-4">
        <label class="text-light">Monto mínimo de apuesta</label>
        <input
          v-model="objParametros.monto_min_apuesta"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-md-4">
        <label class="text-light">Límite de apuesta</label>
        <input
          v-model="objParametros.limite_apuesta"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-4">
        <label class="text-light">Límite de pago</label>
        <input
          v-model="objParametros.limite_pago"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-md-2">
        <label class="text-light">Status</label>
        <div class="form-check form-switch text-center">
          <input
            v-model="objParametros.status"
            class="form-check-input"
            type="checkbox"
            :checked="objParametros.status"
          />
          <label v-if="objParametros.status" class="form-check-label text-light"
            >Activo</label
          >
          <label v-else class="form-check-label text-light">Inactivo</label>
        </div>
      </div>
    </div>

    <div class="row justify-content-center py-4">
      <div class="col-md-2">
        <button class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject } from "vue-demi";
import { useStore } from "vuex";
import useParametros from "../composable/useParametros";
import Swal from "sweetalert2";
import useUtil from "../../../use/useUtil";
export default {
  setup() {
    const objParametros = inject("objParametros");
    const frmHipismo = inject("frmHipismo");
    const store = useStore();
    const datos = inject("datos");
    const { ConvertStringtoDecimal } = useUtil();
    const { createParametros, editParametros, getParametros, lstParametros } =
      useParametros();

    const submitHipismo = async () => {
      let resp = "";
      store.commit("showLoader");
      if (objParametros.id) {
        objParametros.monto_min_recarga = ConvertStringtoDecimal(
          objParametros.monto_min_recarga
        );
        objParametros.monto_max_recarga = ConvertStringtoDecimal(
          objParametros.monto_max_recarga
        );
        objParametros.monto_min_apuesta = ConvertStringtoDecimal(
          objParametros.monto_min_apuesta
        );
        objParametros.monto_max_apuesta_directa = ConvertStringtoDecimal(
          objParametros.monto_max_apuesta_directa
        );
        objParametros.monto_min_retiro = ConvertStringtoDecimal(
          objParametros.monto_min_retiro
        );
        objParametros.monto_max_retiro = ConvertStringtoDecimal(
          objParametros.monto_max_retiro
        );
        objParametros.bonif_max = ConvertStringtoDecimal(
          objParametros.bonif_max
        );
        objParametros.monto_max_retiro_pagomovil = ConvertStringtoDecimal(
          objParametros.monto_max_retiro_pagomovil
        );
        objParametros.limite_pago = ConvertStringtoDecimal(
          objParametros.limite_pago
        );
        objParametros.limite_apuesta = ConvertStringtoDecimal(
          objParametros.limite_apuesta
        );
        resp = await editParametros(objParametros);
      } else {
        objParametros.monto_min_recarga = ConvertStringtoDecimal(
          objParametros.monto_min_recarga
        );
        objParametros.monto_max_recarga = ConvertStringtoDecimal(
          objParametros.monto_max_recarga
        );
        objParametros.monto_min_apuesta = ConvertStringtoDecimal(
          objParametros.monto_min_apuesta
        );
        objParametros.monto_max_apuesta_directa = ConvertStringtoDecimal(
          objParametros.monto_max_apuesta_directa
        );
        objParametros.monto_min_retiro = ConvertStringtoDecimal(
          objParametros.monto_min_retiro
        );
        objParametros.monto_max_retiro = ConvertStringtoDecimal(
          objParametros.monto_max_retiro
        );
        objParametros.bonif_max = ConvertStringtoDecimal(
          objParametros.bonif_max
        );
        objParametros.monto_max_retiro_pagomovil = ConvertStringtoDecimal(
          objParametros.monto_max_retiro_pagomovil
        );
        objParametros.limite_pago = ConvertStringtoDecimal(
          objParametros.limite_pago
        );
        objParametros.limite_apuesta = ConvertStringtoDecimal(
          objParametros.limite_apuesta
        );
        resp = await createParametros(objParametros);
      }

      await getParametros();
      store.commit("hideLoader");
      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }

      datos.value = lstParametros.value.filter((e) => e.tipo == "Hipismo");
      cerrarHipismo();
    };

    const cerrarHipismo = () => {
      frmHipismo.value = false;
    };

    return {
      objParametros,
      submitHipismo,
      cerrarHipismo,
    };
  },
};
</script>

<style  scoped>
.hipismo {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  width: 50em;
  margin: auto;
}

@media (max-width: 1000px) {
  .hipismo {
    width: 100%;
  }
}
</style>
import { computed } from "vue";
import { useStore } from "vuex";

const useParametros = () => {
  const store = useStore();

  const createParametros = async (obj) => {
    const resp = await store.dispatch("parametros/createParametros", obj);
    return resp;
  };
  const editParametros = async (obj) => {
    const resp = await store.dispatch("parametros/editParametros", obj);
    return resp;
  };
  const deleteParametros = async (obj) => {
    const resp = await store.dispatch("parametros/deleteParametros", obj);
    return resp;
  };
  const getParametros = async () => {
    const resp = await store.dispatch("parametros/getParametros");
    return resp;
  };


  return {
    createParametros,
    editParametros,
    deleteParametros, 
    getParametros,   
    lstParametros: computed(() => store.getters["parametros/getParametros"]),    
  };
};

export default useParametros;

<template>
  <div v-if="!frmHipismo">
    <div class="text-end mb-2 mt-2">
      <button class="myButtonAceptar" @click="agregarHipismo">Agregar</button>
    </div>

    <div class="card table-responsive-sm">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">clacificación</th>
            <th scope="col">Monto mínimo apuesta</th>
            <th scope="col">Límite apuesta</th>
            <th scope="col">Límite de pago</th>
            <th scope="col">Estatus</th>
            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody v-for="d of datos" v-bind:key="d.id">
          <tr style="cursor: pointer">
            <td @click="select(d)">{{ d.clacificacion }}</td>
            <td @click="select(d)">{{ formatearNumero(d.monto_min_apuesta) }}</td>
            <td @click="select(d)">{{ formatearNumero(d.limite_apuesta) }}</td>
            <td @click="select(d)">{{ formatearNumero(d.limite_pago) }}</td>
            <td @click="select(d)">{{ validarStatus(d.status) }}</td>
            <td>
              <button class="btnEliminarTable" @click="eliminarHipismo(d)">
                <i style="color: #fff" class="far fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <HipismoForm v-else />
</template>

<script>
import { inject, provide, ref } from "vue-demi";
import HipismoForm from "./HipismoForm.vue";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import useParametros from "../composable/useParametros";
import useUtil from '../../../use/useUtil';

export default {
  components: {
    HipismoForm,
  },
  setup() {
    const datos = inject("datos");
    const frmHipismo = ref(false);
    const objParametros = inject("objParametros");
    const store = useStore();
    const {formatearNumero} = useUtil();
    const { getParametros, deleteParametros, lstParametros } = useParametros();

    const validarStatus = (e) => {
      if (e) {
        return "Activo";
      }
      return "Inactivo";
    };

    const eliminarHipismo = async (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          store.commit("showLoader");
          objParametros.id = e.id;
          await deleteParametros(objParametros);
          await getParametros();
          store.commit("hideLoader");         
          ActulizarTable();
        }
      });
    };

    const ActulizarTable = () => {
      if (objParametros.tipo == "Hipismo") {
        datos.value = lstParametros.value.filter(
          (e) => e.tipo == objParametros.tipo
        );
      }
    };

    const agregarHipismo = () => {
      objParametros.id = null;
      objParametros.tipo = "Hipismo";
      objParametros.monto_min_recarga = 0;
      objParametros.monto_max_recarga = 0;
      objParametros.monto_min_apuesta = 0;
      objParametros.monto_max_apuesta_directa = 0;
      objParametros.monto_min_retiro = 0;
      objParametros.monto_max_retiro = 0;
      objParametros.bonif_max = 0;
      objParametros.monto_max_retiro_pagomovil = 0;
      objParametros.limite_pago = 0;
      objParametros.limite_apuesta = 0;
      objParametros.status = true;
      objParametros.deleted = false;
      objParametros.clacificacion = "";
      frmHipismo.value = true;
    };

    const select = (e) => {
      objParametros.id = e.id;
      objParametros.tipo = "Hipismo";
      objParametros.monto_min_recarga = formatearNumero(e.monto_min_recarga);
      objParametros.monto_max_recarga = formatearNumero(e.monto_max_recarga);
      objParametros.monto_min_apuesta = formatearNumero(e.monto_min_apuesta);
      objParametros.monto_max_apuesta_directa = formatearNumero(e.monto_max_apuesta_directa);
      objParametros.monto_min_retiro = formatearNumero(e.monto_min_retiro);
      objParametros.monto_max_retiro = formatearNumero(e.monto_max_retiro);
      objParametros.bonif_max = formatearNumero(e.bonif_max);
      objParametros.monto_max_retiro_pagomovil = formatearNumero(e.monto_max_retiro_pagomovil);
      objParametros.limite_pago = formatearNumero(e.limite_pago);
      objParametros.limite_apuesta = formatearNumero(e.limite_apuesta);
      objParametros.status = e.status;
      objParametros.deleted = e.deleted;
      objParametros.clacificacion = e.clacificacion;
      frmHipismo.value = true;
    };

    provide("frmHipismo", frmHipismo);
    return {
      datos,
      validarStatus,
      eliminarHipismo,
      frmHipismo,
      agregarHipismo,
      select,
      formatearNumero
    };
  },
};
</script>

<style>
</style>
<template>
  <div class="container-fluid">
    <h3 class="text-light">Parametros</h3>
    <div class="row">
      <div class="col-md-2">
        <select
          name=""
          id=""
          class="form-select"
          v-model="objParametros.tipo"
          @change="filtarTipo"
        >
          <option value="USD">USD</option>
          <option value="VES">VES</option>
          <option value="COP">COP</option>
          <option value="PEN">PEN</option>
          <option value="CLP">CLP</option>
          <option value="Hipismo">Hipismo</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ParametrosForm v-if="objParametros.tipo != 'Hipismo'" />
        <HipismoTables v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, provide, reactive, ref } from "vue-demi";
import { useStore } from "vuex";
import ParametrosForm from "../components/ParametrosForm.vue";
import HipismoTables from "../components/HipismoTables.vue";

import useParametros from "../composable/useParametros";
import useUtil from "../../../use/useUtil";

export default {
  components: {
    ParametrosForm,
    HipismoTables,
  },
  setup() {
    const store = useStore();
    const datos = ref([]);
    const { getParametros, lstParametros } = useParametros();
    const { formatearNumero } = useUtil();
    const boxParametros = reactive({
      activo: 0,
      inactivo: 0,
      total: 0,
      titulo: "Parametros",
    });
    const objParametros = reactive({
      id: null,
      tipo: "",
      monto_min_recarga: 0,
      monto_max_recarga: 0,
      monto_min_apuesta: 0,
      monto_max_apuesta_directa: 0,
      monto_min_retiro: 0,
      monto_max_retiro: 0,
      bonif_max: 0,
      monto_max_retiro_pagomovil: 0,
      limite_pago: 0,
      limite_apuesta: 0,
      status: true,
      deleted: false,
      clacificacion: "",
    });

    onMounted(async () => {
      store.commit("showLoader");
      await getParametros();
      store.commit("hideLoader");
    });

    const filtarTipo = () => {
      if (objParametros.tipo == "Hipismo") {
        datos.value = lstParametros.value.filter(
          (e) => e.tipo == objParametros.tipo
        );
      } else {
        let dataFiltrada = lstParametros.value.filter(
          (e) => e.tipo == objParametros.tipo
        );

        if (dataFiltrada.length > 0) {
          objParametros.id = dataFiltrada[0].id;
          objParametros.tipo = dataFiltrada[0].tipo;
          objParametros.monto_min_recarga = formatearNumero(
            dataFiltrada[0].monto_min_recarga
          );
          objParametros.monto_max_recarga = formatearNumero(
            dataFiltrada[0].monto_max_recarga
          );
          objParametros.monto_min_apuesta = formatearNumero(
            dataFiltrada[0].monto_min_apuesta
          );
          objParametros.monto_max_apuesta_directa = formatearNumero(
            dataFiltrada[0].monto_max_apuesta_directa
          );
          objParametros.monto_min_retiro = formatearNumero(
            dataFiltrada[0].monto_min_retiro
          );
          objParametros.monto_max_retiro = formatearNumero(
            dataFiltrada[0].monto_max_retiro
          );
          objParametros.bonif_max = formatearNumero(dataFiltrada[0].bonif_max);
          objParametros.monto_max_retiro_pagomovil = formatearNumero(
            dataFiltrada[0].monto_max_retiro_pagomovil
          );
          objParametros.limite_pago = formatearNumero(
            dataFiltrada[0].limite_pago
          );
          objParametros.limite_apuesta = formatearNumero(
            dataFiltrada[0].limite_apuesta
          );
          objParametros.status = dataFiltrada[0].status;
          objParametros.deleted = dataFiltrada[0].deleted;
          objParametros.clacificacion = dataFiltrada[0].clacificacion;
        } else {
          objParametros.id = null;
          objParametros.monto_min_recarga = 0;
          objParametros.monto_max_recarga = 0;
          objParametros.monto_min_apuesta = 0;
          objParametros.monto_max_apuesta_directa = 0;
          objParametros.monto_min_retiro = 0;
          objParametros.monto_max_retiro = 0;
          objParametros.bonif_max = 0;
          objParametros.monto_max_retiro_pagomovil = 0;
          objParametros.limite_pago = 0;
          objParametros.limite_apuesta = 0;
          objParametros.status = null;
          objParametros.deleted = null;
          objParametros.clacificacion = null;
        }
      }
    };
    provide("objParametros", objParametros);
    provide("datos", datos);

    return { lstParametros, filtarTipo, objParametros, datos, boxParametros };
  },
};
</script>

<style>
</style>